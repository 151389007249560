<template>
	<!-- Start Template Root Div -->
	<div>
		<WaveLoader v-if="searchSurfWaiversLoading || pageReloading" />

		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div>
							<h1 class="text-2xl">Customer Check-In</h1>
						</div>
						<div>
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<section class="my-10 text-center w-8/12 mx-auto">
				<h2 class="text-xl mb-2 font-bold">
					You've checked in!
				</h2>
				<p>
					We've just checked in the following people...
				</p>
			</section>
			<section class="my-10 w-10/12 mx-auto">
				<div
					v-for="(surfer, index) in checkedInSurfers"
					:key="'surfer-result-2-' + index"
					class="flex items-center justify-around py-5"
				>
					<div class="w-1/4">
						<p>{{ surfer.lastName }}, {{ surfer.firstName }}</p>
					</div>
					<div class="w-1/4">
						<p v-html="surfer.sessionNames"></p>
					</div>
					<div class="w-1/4">
						<p>Session(s) at {{ surfer.sessionTimes }}</p>
					</div>
				</div>
			</section>
			<section class="my-20 text-center w-10/12 mx-auto">
				<p class="text-xl font-bold mb-10">
					Please proceed on-site and enjoy your day!
				</p>
				<button
					class="max-w-1/2 inline-flex bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
					@click="backToStart"
				>
					Next Customer
				</button>
			</section>
		</main>
	</div>
</template>
<script>
	import { mapState, mapActions } from "vuex";

	import WaveLogo from "@/components/WaveLogo.vue";
	import WaveLoader from "@/components/WaveLoader.vue";

	export default {
		components: {
			WaveLogo,
			WaveLoader
		},
		created() {
			if (this.searchSurfWaiversActiveUser === null) {
				this.$router.push({ name: "CheckIn" });
			}
		},
		data() {
			return {
				pageReloading: false
			};
		},
		computed: {
			...mapState("surfers", [
				"searchSurfWaiversLoading",
				"searchSurfWaiversSuccess",
				"searchSurfWaivers",
				"searchSurfWaiversActiveUser"
			]),
			checkedInSurfers() {
				return this.searchSurfWaivers.filter(
					surfer => surfer.onSite === true
				);
			}
		},
		methods: {
			...mapActions(["surfers/resetSearchSurfWaivers"]),
			backToStart() {
				this["surfers/resetSearchSurfWaivers"]();
				this.$router.go({ name: "CheckIn" });
				this.pageReloading = true;
			}
		}
	};
</script>
